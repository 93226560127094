/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus,
*:focus-visible {
  outline-offset: 3px;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
}

:root {
  scroll-behavior: smooth;
}

html {
  scroll-padding-top: 3.15em;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.nav {
  display: flex;
  background: rgb(34, 36, 55);
  padding: 0.5em;
  height: 3.15em;
  width: 100%;
  top: 0;
  position: sticky;
  z-index: 10;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.25);
}
.nav * {
  z-index: 100;
}
.nav__right__img {
  margin-left: 1em;
  max-height: 3em;
  margin-top: 0.075em;
  position: absolute;
  top: 0;
  left: 0;
}
.nav__toggle {
  margin-left: auto;
}
@media only screen and (min-width: 47em) {
  .nav__toggle {
    display: none;
  }
}
.nav__left {
  position: absolute;
  right: 0;
  top: 100%;
  background: rgb(34, 36, 55);
  transition: transform 400ms ease-in-out;
  transform-origin: top;
}
@media only screen and (min-width: 47em) {
  .nav__left {
    position: static;
    opacity: 1;
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}
.nav__list {
  display: flex;
  flex-direction: column;
  gap: 1em;
  list-style-type: none;
  margin-right: 1em;
  padding: 1em;
  transition: opacity 150ms ease-in 350ms;
}
@media only screen and (min-width: 47em) {
  .nav__list {
    flex-direction: row;
    margin: 0;
    padding: 0;
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}
.nav__list_item a {
  color: white;
  text-decoration: none;
  margin-top: auto;
}
.nav__list_item a:hover, .nav__list_item a:focus, .nav__list_item a:focus-within {
  text-decoration: underline;
  text-decoration-color: #964df7;
  text-decoration-thickness: 3px;
  outline: transparent;
}
@media only screen and (max-width: 47em) {
  .nav__left[aria-hidden=true] {
    transform: scaleY(0);
  }
  .nav__left[aria-hidden=true] ul {
    opacity: 0;
    transition: opacity 150ms ease-in;
  }
}
.nav__cta {
  padding: 0.5em;
  background: #964df7;
  border-radius: 5%;
  color: white;
  text-decoration: none;
}
.nav__cta:hover, .nav__cta:focus, .nav__cta:focus-within {
  outline: auto 1px !important;
}
.nav__toggle {
  background: none;
  border: none;
}
.nav__toggle span,
.nav__toggle span::before,
.nav__toggle span::after {
  display: block;
  background: white;
  height: 2px;
  width: 2em;
  border-radius: 2px;
  position: relative;
  transition: transform 400ms ease-in-out;
}
.nav__toggle span::before,
.nav__toggle span::after {
  content: "";
  position: absolute;
}
.nav__toggle span::before {
  bottom: 7px;
  transition: opacity 250ms ease-in-out 400ms;
}
.nav__toggle span::after {
  top: 7px;
}
.nav__toggle[aria-expanded=true] span {
  transform: rotate(45deg);
}
.nav__toggle[aria-expanded=true] span::before {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}
.nav__toggle[aria-expanded=true] span::after {
  transform: rotate(-90deg) translateX(7px);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(34, 36, 55);
  padding: 0.5em;
}
.footer__list {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin-right: 1em;
  padding: 0;
}
.footer__list__item a {
  color: white;
  text-decoration: none;
  margin-top: auto;
}
.footer__list__item a:hover, .footer__list__item a:focus {
  text-decoration: underline;
  text-decoration-color: #964df7;
  text-decoration-thickness: 3px;
  outline: transparent;
}
.footer__logos {
  display: flex;
  width: 100%;
}
.footer__logos__img_wrapper {
  width: 50%;
  height: 100%;
  max-height: 15em;
}
.footer__logos__img {
  width: 50%;
  margin: auto;
}
.footer__legal {
  color: white;
}

body {
  background: rgb(34, 36, 55);
}

.banner {
  display: none;
  background: #964df7;
  color: white;
  padding: 0.25em;
  text-align: center;
  font-size: 1.25em;
  font-weight: bold;
}
.banner--open {
  display: block;
}
.banner a {
  color: white;
  text-decoration: underline;
  text-decoration-color: rgb(34, 36, 55);
  text-decoration-thickness: 3px;
}

.hero {
  background-image: url("/static/img/hero-bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh + 1em);
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero__content {
  background: rgb(34, 36, 55);
  width: 85%;
  color: white;
  padding: 1em 3em;
  margin: 1em;
  border-radius: clamp(20px, 2vw, 50px);
  text-align: center;
}
.hero__content img {
  min-height: 5em;
  margin: auto;
}
@media only screen and (max-width: 700px) {
  .hero__content__logo {
    display: none;
  }
}
.hero__content__logo--mobile {
  display: none;
}
@media only screen and (max-width: 700px) {
  .hero__content__logo--mobile {
    display: block;
  }
}

.about {
  max-width: 60em;
  color: white;
  margin: auto;
  padding: 1em;
}
.about__title {
  text-align: center;
}
.about__paragraph {
  line-height: 200%;
  margin-bottom: 1em;
  max-width: 150ch;
}

.stats {
  background: #964df7;
  max-width: 100vw;
  color: white;
}
.stats__container {
  max-width: 60em;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1em 0;
}
@media only screen and (max-width: 47em) {
  .stats__container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
.stats__stat {
  text-align: center;
}
.stats__num {
  font-size: 2em;
  font-weight: bold;
}

.reasons {
  max-width: 60em;
  margin: auto;
  margin-top: 1em;
  color: white;
}
.reasons__title {
  text-align: center;
}
.reasons__wrapper {
  display: flex;
  flex-direction: column;
}
.reasons__left {
  flex-basis: 25%;
  padding: 1em;
  display: flex;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
}
.reasons__right {
  flex-basis: 75%;
  padding: 1em;
}
.reasons__paragraph {
  display: none;
  line-height: 1.65;
}
.reasons__paragraph a {
  color: #00ccff;
  font-weight: bold;
}
.reasons__paragraph[hidden=false] {
  display: block;
}
.reasons__label {
  display: block;
  width: 100%;
  cursor: pointer;
  padding: 0.5em;
  outline: transparent;
  background: transparent;
  border: transparent;
  color: white;
  position: relative;
}
.reasons__label::after {
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background: #964df7;
  position: absolute;
  transform: scaleX(0);
  transition: transform 750ms ease-out;
}
.reasons__label:focus-visible {
  outline: auto 5px -webkit-focus-ring-color;
}
.reasons__label:hover::after, .reasons__label:focus::after, .reasons__label[aria-expanded=true]::after {
  transform: scaleX(1);
  transition: transform 200ms ease-in;
}
.reasons__label__wrapper {
  position: relative;
  --brand-color: #964df7;
}

.overview {
  text-align: center;
  max-width: 500px;
  margin: auto;
  color: white;
}
.overview__paragraph {
  margin-bottom: 0.25em;
}
.overview__container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  aspect-ratio: 16/9;
}
.overview__container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.testimonials {
  margin: 1em 0;
  color: white;
}
.testimonials__title {
  text-align: center;
  margin-bottom: 0.5em;
}

.carosel {
  padding: 0 5em;
  padding-bottom: 1.2em;
}
.carosel__item {
  border: 2px solid #964df7;
  border-radius: 30px;
  margin: 1em;
}
.carosel__name {
  text-align: center;
  font-weight: bold;
  font-size: 1.15em;
}
.carosel__blurb {
  color: white;
  min-height: 12em;
  max-width: 35ch;
  margin: auto;
  margin-bottom: 0.5em;
  padding: 0.5em;
  position: relative;
  text-align: center;
}
.carosel__blurb__quote {
  font-size: 1rem;
  color: white;
  font-family: serif;
}
.carosel__image {
  max-width: 5em;
  margin: auto;
}

.faq {
  max-width: 60em;
  margin: auto;
  margin-bottom: 1em;
}
.faq__title {
  text-align: center;
  color: white;
}
.faq__item {
  margin: 1em;
  padding: 1em;
  display: block;
  border-bottom: 2px solid #964df7;
  text-align: left;
}
.faq__q {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
}
.faq__q .chevron {
  transition: transform 150ms ease;
}
.faq__q__text {
  color: white;
}
.faq__q::-webkit-details-marker {
  display: none;
}
.faq__a {
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 1000ms ease;
  display: none;
}
.faq__a p {
  color: white;
}
.faq__a a {
  color: #00ccff;
  font-weight: bold;
}
.faq__item.active .chevron {
  transform: rotate(-180deg);
}
.faq__item.active .faq__a {
  transform: scaleY(1);
  display: block;
}

.download {
  max-width: 60em;
}
.download__title {
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1.5em;
  color: white;
}
.download__container {
  display: flex;
  justify-content: space-evenly;
  gap: 3em;
  max-width: 60em;
  margin: auto;
  padding: 0 3em;
}
@media only screen and (max-width: 47em) {
  .download__container {
    flex-direction: column;
  }
}
.download__item {
  padding: 3.5em 1em;
  text-align: center;
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.25);
  border-radius: 0.65em;
  flex: 1 1 0;
  background: white;
}
@media only screen and (max-width: 47em) {
  .download__item {
    margin: auto;
    padding: 2em;
  }
}
.download__item__platform {
  text-align: center;
}
.download__item--primary {
  background: #964df7;
  color: white;
  transform: scale(1.1);
}
.download__item--primary .download__item__platform {
  color: white;
}
.download__item--primary .download__link {
  border-color: white;
  color: white;
}
.download__item--primary .download__link:hover,
.download__item--primary .download__link:focus {
  background-color: white;
  color: #964df7;
  outline: transparent;
}
.download__features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 3em;
}
.download__link {
  padding: 0.5em 1em;
  border: 3px solid #964df7;
  color: #964df7;
  font-weight: bold;
  text-decoration: none;
  border-radius: 0.65em;
  transition: background-color 150ms ease-in, color 150ms ease-in;
}
.download__link:hover, .download__link:focus {
  color: white;
  background: #964df7;
  outline: transparent;
}
.download__extras {
  text-align: center;
  color: white;
  font-size: 1.25rem;
  margin: auto;
  margin-top: 2em;
}
.download__extras__link {
  color: #00ccff;
  font-weight: bold;
  display: inline-block;
  transition: ease 500ms transform;
}
.download__extras__link:hover {
  transform: scale(1.05);
  transition: 250ms ease transform;
}

.team {
  margin: auto;
}
.team__title {
  text-align: center;
  margin-top: 1.5em;
  color: white;
}
.team__subtitle {
  font-size: larger;
  color: white;
}
.team__level {
  text-align: center;
  width: 100%;
}
.team__level__no_carosel {
  display: flex;
  justify-content: center;
}
.team__level__no_carosel ul {
  display: flex;
  gap: 3em;
}
.team__person {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 1.5em 0;
}
.team__person__img {
  max-width: 6em;
  display: block;
  margin: 1em auto;
  border-radius: 50%;
}
.team__person__title {
  max-width: 20ch;
  margin: auto;
  color: #00ccff;
}
.team__person__name {
  font-weight: bold;
  font-size: large;
  color: white;
}

#staff-leader .team__person img {
  max-width: 9em;
}

#staff-advisor .team__person img {
  max-width: 8em;
}

#staff-core .team__person img {
  max-width: 7em;
}

.thanks {
  max-width: 60em;
  margin: auto;
  color: white;
}
.thanks__title {
  text-align: center;
  margin-top: 1.5em;
}

.translators__title {
  margin: 0.5em 0;
  text-align: center;
}
.translators__content_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media only screen and (max-width: 47em) {
  .translators__content_wrapper {
    grid-template-columns: 1fr;
    width: 75vw;
  }
}
.translators__list {
  margin: auto;
}
@media only screen and (max-width: 47em) {
  .translators__list {
    max-width: 30ch;
  }
}

.patreons__title {
  margin-top: 1em;
  text-align: center;
}
.patreons__content_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media only screen and (max-width: 47em) {
  .patreons__content_wrapper {
    grid-template-columns: 1fr;
  }
}
.patreons__list {
  list-style: none;
  padding: 0;
}
.patreons__list__item {
  text-align: center;
}
.patreons__list__item--title {
  font-size: 1.3rem;
  margin-bottom: 0.75em;
}
.patreons__list__item--title::marker {
  color: transparent;
}

.splide__pagination__page.is-active {
  background: #964df7 !important;
}
